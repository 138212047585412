import ApiService from "@/service/ApiService";

export default function useAtk() {

  const GetAtkPic = async (id) => {
    ApiService.setHeader();
    return await ApiService.query("getatkpicture/" + id);
  };

  return {
    GetAtkPic,
  };
}
