<template>
  <div class="row">
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div
          class="card-header white text-center"
          style="background-image: linear-gradient(to top, #4ba616, #1b2913)"
        >
          <img
            src="@/assets/logo.png"
            style="
              height: 100px;
            "
            alt="..."
          />
          <!-- <div>
            <i @click="edit" class="bi bi-gear icon"></i>
          </div> -->
        </div>

        <Form
          @submit="onSubmitRegister"
          :validation-schema="register"
          id="kt_account_profile_details_form"
          ref="formContainer"
          novalidate="novalidate"
        >
          <div class="card-body mb-47 mt-2 white">
            <div class="title text-end">
              <i
                style="
                  font-size: 20px;
                  color: steelblue;
                  float: left;
                  margin-top: -5px;
                "
                class="bi bi-person-circle"
                ><span style="font-size: 14px; margin-left: 5px; color: black"
                  >แก้ไขผลตรวจ ATK</span
                ></i
              >
              <i class="bi bi-arrow-bar-left"></i
              ><span @click="back" class="title-text">กลับหน้าหลัก</span>
            </div>
            <div class="card-body">
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-5"
                  >รูปเดิม</label
                >

                <div style="text-align: center">
                  <img
                    v-if="Profire.src"
                    :src="Profire.src"
                    class="img-fluid"
                    style="
                      margin-top: 10px;
                      height: 300px;
                      object-fit: cover;
                      border-radius: 5%;
                      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                    "
                  />
                </div>
              </div>

              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >ผลตรวจ ATK (1 รูป)</label
                >

                <!--end::Label-->

                <!--begin::Col-->

                <div class="col-lg-8 fv-row">
                  <div
                    v-if="imageUrl.length !== 0"
                    class="imagePreviewWrapper"
                    style="padding: 15px 0px"
                  >
                    <div class="row">
                      <div
                        style="position: relative"
                        class="col-4"
                        v-for="(item, index) in imageUrl"
                        :key="index"
                      >
                        <div class="xx" @click="deleteImage(index)">
                          <span>x</span>
                        </div>
                        <img class="images" :src="item" />
                      </div>
                    </div>
                  </div>
                  <input
                    type="file"
                    id="getFilecamera"
                    multiple="multiple"
                    accept="image/jpg, image/jpeg ,image/png"
                    @input="pickFile"
                    style="display: none"
                    capture="camera"
                  />
                  <input
                    type="file"
                    id="getFile"
                    multiple="multiple"
                    accept="image/jpg, image/jpeg, image/png"
                    @input="pickFile"
                    style="display: none"
                  />
                  <div class="d-flex justify-content-center">
                    <button
                      type="button"
                      @click="selectImage(1)"
                      class="button-upload"
                    >
                      ถ่ายรูป<img src="@/assets/camera.png" class="icon-ima" />
                    </button>
                    <div style="padding-left: 8px; padding-right: 8px"></div>
                    <button
                      type="button"
                      @click="selectImage(2)"
                      class="button-upload"
                    >
                      แนบรูป<img src="@/assets/picture.png" class="icon-ima" />
                    </button>

                    <Field type="hidden" name="imageUrl" v-model="imageUrl" />
                  </div>

                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="imageUrl" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
            </div>
            <!--end::Card body-->
          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <button type="submit" class="button">ยืนยันการแก้ไข</button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import liff from "@line/liff";
import { defineComponent, ref, onMounted, inject } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import useSweetalert from "@/service/sweetalert2";
import useRegister from "@/service/api/register";
export default defineComponent({
  name: "Register",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
  
    const store = inject("store");
    const router = inject("router");
    const accesstoken = store.getters.getAccessToken.accesstoken;
    const { SconfirmNc, Sconfirm, SToast } = useSweetalert();
    const imageUrl = ref([]);
    const atk_img = ref([]);
    const { UpdateUser_atk_image } = useRegister();
    let isLoading = ref(false);
    const Profire = store.getters.getProfire;
    useRegister;
    const register = Yup.object().shape({
      imageUrl: Yup.array().min(1, "กรุณาแนบรูปภาพใหม่"),
    });

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      } 
    });

   

    const onSubmitRegister = (values) => {
      values.id_line = store.getters.getAccessToken.accesstoken;
      values.old_image_name = Profire.src.replace(
        "https://api.gokhanom.siaminno.co.th/storage/upload/atk2/",
        ""
      ); // ทำการ replace ตัดพาทออกไปเหลือแต่ชื่อรูป
      console.log(values);
      Sconfirm("ยืนยัน การแก้ไขข้อมูล?", "question").then(async (result) => {
        //console.log(result);

        if (result.isConfirmed) {
          isLoading.value = true;
          await UpdateUser_atk_image(values)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("แก้ไขข้อมูลสำเร็จ", "success", "ตกลง").then(() => {
                  router.push({ name: "Home" });
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data.errors;

                if (errors.id_line) {
                  SToast("error", `${errors.id_line[0]}`);
                } else {
                  SToast("error", "เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง");
                }
              }
            });
        }
      });
    };

    const selectImage = (e) => {
      if (e === 1) {
        document.getElementById("getFilecamera").click();
      } else {
        document.getElementById("getFile").click();
      }
    };

    const deleteImage = (val) => {
      imageUrl.value.splice(val, 1);
    };

    const pickFile = async (e) => {
      const files = e.target.files;

      // console.log(files.length);
      // console.log(imageUrl.value.length);
      if (files.length <= 1) {
        if (imageUrl.value.length < 1) {
          for (var i = 0; i < files.length; i++) {
            imageUrl.value.push(
              await reduce_image_file_size(await image_to_base64(files[i]))
            );
          }
        } else {
          SconfirmNc("แนบรูปได้ไม่เกิน 1 รูป", "error", "ตกลง");
        }
      } else {
        SconfirmNc("แนบรูปได้ไม่เกิน 1 รูป", "error", "ตกลง");
      }
    };

    async function reduce_image_file_size(
      base64Str,
      MAX_WIDTH = 1200,
      MAX_HEIGHT = 1200
    ) {
      let resized_base64 = await new Promise((resolve) => {
        let img = new Image();

        img.src = base64Str;
        img.onload = () => {
          let canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");

          ctx.drawImage(img, 0, 0, width, height);

          resolve(canvas.toDataURL("image/jpeg")); // this will return base64 image results after resize
        };
      });
      return resized_base64;
    }

    async function image_to_base64(file) {
      let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();

        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = (error) => {
          console.log(error);
          alert("An Error occurred please try again, File might be corrupt");
        };
        fileReader.readAsDataURL(file);
      });

      return result_base64;
    }

    const back = () => {
      router.push({ name: "Profile" });
    };

    return {
      onSubmitRegister,
      register,
      deleteImage,
      accesstoken,
      selectImage,
      pickFile,
      imageUrl,
      atk_img,
      Profire,
      back,
    };
  },
});
</script>

<style scoped>
.bdhd {
  border-bottom: 2px solid #dee2e6 !important;
}
.icon {
  width: 50px;
  padding: 5px;
}
.gender {
  padding: 30px 20px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
}
input[type="radio"] {
  display: none;
}
input:checked + span {
  color: var(--color);
  border: 2px solid var(--color);
}

.images {
  width: 100%;
}
.xx {
  position: absolute;
  background-color: #b92735;
  color: white;
  right: 11px;
  padding: 6px;
}

.button-upload {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: 100%;
}

.button-upload:hover,
.button-upload:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.button-upload:hover {
  transform: translateY(-1px);
}

.button-upload:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}
</style>
