<template>
  <div class="bg-main vh100">
    <Loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :color="color" loader="dots" />
    <div class="col-12">
      <div class="card concard ">
        <div class="card-header text-center" style="height: 150px;position: relative;">
          <div class="t1 mt-3" style=" color: white">
            ลงทะเบียนเข้าร่วมงาน
          </div>
          <img src="@/assets/logo.png" style="
                                              height: 120px;
                                              bottom: -20%;
                                              left: 50%;
                                              transform: translateX(-50%);
                                              position: absolute;
                                            " />

        </div>
        <Form @submit="onSubmitRegister" :validation-schema="register" id="kt_account_profile_details_form"
          ref="formContainer" novalidate="novalidate">
          <div class="card-body mt-2  ms-2 me-2 white" style="border-radius: 25px;">

            <div class="t1 mt-4 text-center" style=" color: #1B3459;">
              มาตราการสำหรับเข้าร่วมงาน
            </div>

            <div class="text-center mt-2">
              ขอความร่วมมือผู้เข้าร่วมงานแนบหลักฐาน อย่างใดอย่างหนึ่ง*
            </div>


            <div class="row justify-content-center">
              <div class="col-11 mt-2 text-start">

                <li>เอกสารยืนยันการฉีดวัคซีน (2 เข็มขึ้นไป) จากแอพหมอพร้อม</li>
                <li>ผลตรวจ ATK ไม่เกิน 48 ชั่วโมงก่อนเวลาเริ่มงาน</li>

              </div>
            </div>





            <div class="row mb-6">




              <!--begin::Label-->
              <label class="col-lg-4 col-form-label required fw-bold fs-6">แนบรูป (1 รูป)</label>

              <!--end::Label-->

              <!--begin::Col-->

              <div class="col-lg-8 fv-row">
                <div v-if="imageUrl.length !== 0" class="imagePreviewWrapper" style="padding: 15px 0px">
                  <div class="row justify-content-center">
                    <div style="position: relative" class="col-6" v-for="(item, index) in imageUrl" :key="index">
                      <div class="xx" @click="deleteImage(index)">
                        <span>x</span>
                      </div>
                      <img class="images" :src="item" />
                    </div>
                  </div>
                </div>
                <input type="file" id="getFilecamera" multiple="multiple" accept="image/jpg, image/jpeg ,image/png"
                  @input="pickFile" style="display: none" capture="camera" />
                <input type="file" id="getFile" multiple="multiple" accept="image/jpg, image/jpeg, image/png"
                  @input="pickFile" style="display: none" />
                <div class="d-flex justify-content-center">
                  <button type="button" @click="selectImage(1)" class="button-upload">
                    ถ่ายรูป<img src="@/assets/camera.png" class="icon-ima" />
                  </button>
                  <div style="padding-left: 8px; padding-right: 8px"></div>
                  <button type="button" @click="selectImage(2)" class="button-upload">
                    แนบรูป<img src="@/assets/picture.png" class="icon-ima" />
                  </button>

                  <Field type="hidden" name="imageUrl" v-model="imageUrl" />
                </div>

                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="imageUrl" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
            </div>

            <div class="row text-center ms-2 me-2">
              <button type="button" @click="back" class="button me-2"
                style="background-color: white; font-size: 16px !important; color: var(--color);">กลับ</button>

              <button type="submit" class="button"
                style="background-color: var(--color); font-size: 16px !important;">ลงทะเบียน</button>

            </div>
          </div>

        </Form>
      </div>
    </div>
</div>
</template>

<script>
import moment from "moment/dist/moment.js";
import liff from "@line/liff";
import useRegister from "@/service/api/register";
import Loading from "vue-loading-overlay";
import { defineComponent, ref, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import useSweetalert from "@/service/sweetalert2";
export default defineComponent({
  name: "Register",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
  },
  setup() {
   

    const { Register } = useRegister();
    let isLoading = ref(false);
    const { SconfirmNc, Sconfirm, SToast } = useSweetalert();
    const imageUrl = ref([]);

    const store = inject("store");
    const router = inject("router");
    let color = ref(process.env.VUE_APP_COLOR);
    const data_register = store.getters.getRegister;


    const register = Yup.object().shape({
      imageUrl: Yup.array().min(1, "กรุณาแนบรูปภาพ"),
    });

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const check_birthdate = (birthday) => {
      var birthdate = moment(birthday, "YYYYMMDD");
      var date_compare = moment("YYYYMMDD");
      var chk_age = moment().diff(moment(birthdate, date_compare), "years");
      //console.log(chk_age);
      return chk_age;
    };


    const onSubmitRegister = (values) => {
      values.type = 'people';
      values.age = check_birthdate(store.getters.getRegister.birthday);
      store.dispatch(SET_REGISTER, values);

      console.log(store.getters.getRegister);
      Sconfirm("ยืนยัน การลงทะเบียน?", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;

          await Register(store.getters.getRegister)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("ลงทะเบียนสำเร็จ", "success", "ตกลง").then(() => {
                  if (store.getters.getUrlCallBack.id) {
                    liff.ready.then(() => {
                      liff.openWindow({
                        url: store.getters.getUrlCallBack.id,
                        external: false,
                      });
                    });
                  } else {
                    router.push({ name: "Home" });
                  }
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data.errors;

                if (errors.id_line) {
                  SToast("error", `${errors.id_line[0]}`);
                } else {
                  SToast("error", "เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง");
                }
              }
            });
        }
      });
    };



 

    const selectImage = (e) => {
      if (e === 1) {
        document.getElementById("getFilecamera").click();
      } else {
        document.getElementById("getFile").click();
      }
    };

    const deleteImage = (val) => {
      imageUrl.value.splice(val, 1);
    };

    
    const back = () => {
      router.push({ name: "Register_1" });
    };

    const pickFile = async (e) => {
      const files = e.target.files;
      if (files.length <= 1) {
        if (imageUrl.value.length < 1) {
          for (var i = 0; i < files.length; i++) {
            imageUrl.value.push(
              await reduce_image_file_size(await image_to_base64(files[i]))
            );
          }
        } else {
          SconfirmNc("แนบรูปได้ไม่เกิน 1 รูป", "error", "ตกลง");
        }
      } else {
        SconfirmNc("แนบรูปได้ไม่เกิน 1 รูป", "error", "ตกลง");
      }
    };

    async function reduce_image_file_size(
      base64Str,
      MAX_WIDTH = 1200,
      MAX_HEIGHT = 1200
    ) {
      let resized_base64 = await new Promise((resolve) => {
        let img = new Image();

        img.src = base64Str;
        img.onload = () => {
          let canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");

          ctx.drawImage(img, 0, 0, width, height);

          resolve(canvas.toDataURL("image/jpeg")); // this will return base64 image results after resize
        };
      });
      return resized_base64;
    }

    async function image_to_base64(file) {
      let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();

        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = (error) => {
          console.log(error);
          alert("An Error occurred please try again, File might be corrupt");
        };
        fileReader.readAsDataURL(file);
      });

      return result_base64;
    }

    return {
      onSubmitRegister,
      register,
      data_register,
      selectImage,
      pickFile,
      imageUrl,
      deleteImage,
      color,
      isLoading,
      back
    };
  },
});
</script>

<style scoped>
.bdhd {
  border-bottom: 2px solid #dee2e6 !important;
}

.icon {
  width: 50px;
  padding: 5px;
}

.gender {
  padding: 30px 20px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
}

input[type="radio"] {
  display: none;
}

input:checked+span {
  color: var(--color);
  border: 2px solid var(--color);
}

.icon-ima {
  width: 30px;
  margin-left: 5px;
}

.btn-outline-secondary {
  border: 1px solid var(--color) !important;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
}

.images {
  width: 100%;
}

.xx {
  position: absolute;
  background-color: #b92735;
  color: white;
  right: 11px;
  padding: 6px;
}

.button-upload {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: 100%;
}

.button-upload:hover,
.button-upload:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.button-upload:hover {
  transform: translateY(-1px);
}

.button-upload:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

li {
  font-size: 12px;

}
</style>
